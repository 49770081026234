


































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getNameByEmail } from '@/utils/users';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({

  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: String, required: true }) tasqId!: string;

  get tasq(): TasqJob|undefined {
	  
	  if (!tasqsListModule.tasqById(this.tasqId)) {
		  return workspaceModule.tasqById(this.tasqId)
	  }
    return tasqsListModule.tasqById(this.tasqId);
  }




  get fullName(): string {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }

  getScheduleJobDateString(date) {
	  return date.toISOString().substring(0, 10);
  }

  //   get fullName(): string {
  // 	  return getNameByEmail(this.tasq?.username);
  //   }

  getOverridenRealTimeLabel(label) {
	  if (label.toLowerCase() == 'rtd') {
		  return 'Deferment';
	  }
	  if (label.toLowerCase() == 'hiline') {
		  return 'Line Pressure';
	  }
	  return label;
  }

  goToTasqPage() {
    // this.$router.replace({
    //   name: 'Tasqs',
    //   params: {
    //     id: this.tasq?.id || '',
    //   },
    //   query: {
    //     type: 'id',
    //   }
    // });

    tasqsListModule.setActiveTasq(this.tasq?.id || '');
    tasqsListModule.setKanbanSelectedTasq(this.tasq?.id || '');
    this.$emit('set-hide-well');
  }
}

function isTasqActive(id) {
  if (window.location.href.includes(id)) {
    return true;
  }
  return false;
}
